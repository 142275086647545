export default {
  namespaced: true,
  state: {
    corporation: null, //会社情報（選択中）
    rootCorporationId: null, // 親会社ID
    corporationId: null, // 切り替え後の会社ID
    token: null
  },
  getters: {
    token: (state) => {
      return state.token
    },
    corporationId: (state) => {
      return state.corporationId
    },
    memberCorporationId: (state) => {
      if (state.corporation) {
        return state.corporation.member_corporation_id || state.corporationId
      }
      return state.corporationId
    },
    userTags: (state) => {
      if (state.corporation) {
        return state.corporation.member_tags
      }
      return []
    },
    useMobile: (state) => {
      if (state.corporation) {
        return state.corporation.use_mobile
      }
      return false
    },
    userTagMap: (state, getters) => {
      return getters.userTags.reduce((acc, v) => {
        acc[v.id] = v.name
        return acc
      }, {})
    },
    sortedUserTagIds: (state, getters) => (tagIds) => {
      return tagIds ? getters.userTags.filter(v => tagIds.indexOf(v.id) >= 0).map(v => v.id) : []
    },
    sortedUserTagNames: (state, getters) => (tagIds) => {
      return tagIds ? getters.userTags.filter(v => tagIds.indexOf(v.id) >= 0).map(v => v.name) : []
    }
  },
  mutations: {
    create (state, user) {
      state.rootCorporationId = user.attributes['custom:corporationId']
      state.token = user.signInUserSession.idToken
    },
    async switchCorporation(state, corporation) {
      // デフォルト値を設定
      const member_tags = corporation.member_tags || []
      const use_mobile = typeof corporation.use_mobile === 'boolean' ? corporation.use_mobile : false
      // ステートを更新
      state.corporation = { ...corporation, member_tags, use_mobile }
      state.corporationId = corporation.corporation_id || null
    },
    userTags (state, userTags) {
      const tags = state.corporation.member_tags
      if (tags.length) tags.splice(0, tags.length) // delete all
      tags.push(...userTags)
    },
    destroy (state) {
      state.corporation = null
      state.rootCorporationId = null
      state.corporationId = null
      state.token = null
    }
  },
  actions: {
    async create ({ commit }, user) {
      commit('create', user)
    },
    async switchCorporation ({ commit }, corporation) {
      await commit('switchCorporation', corporation)
    },
    async refreshUserTags({ commit, dispatch, state }) {
      // 別ユーザによる更新を考慮して再取得する
      if (state.corporation) {
        const memberCorporationId = state.corporation.member_corporation_id || state.corporationId
        const params = {'corporation_id': memberCorporationId}
        const corp = await dispatch('http/get', { apiName: 'canaryDynamo', path: '/corporation_account', params: params }, { root: true })
          .then(res => res.data[0])
          .catch(() => null)
        commit('userTags', corp ? corp.member_tags || [] : [])
      }
    },
    saveUserTags ({ commit }, userTags) {
      commit('userTags', userTags)
    },
    destroy ({ commit }) {
      commit('destroy')
    }
  }
}
