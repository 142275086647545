import axios from 'axios'
import { Agent as HttpAgent } from 'http'
import { Agent as HttpsAgent } from 'https'

const apiRoot = 'https://d3jdezdsmsz41g.cloudfront.net'
const endpoints = Object.freeze({
  canaryDynamo: apiRoot + '/api/dynamo',
  canaryConfig: apiRoot + '/api/corp-config',
  canaryCorp: apiRoot + '/api/corp',
  canaryCorpToken: apiRoot + '/api/corp-token',
  canaryCorpTags: apiRoot + '/api/corp-tags',
  canaryEmployee: apiRoot + '/api/employee',
  canaryGuide4AppPdf: apiRoot + '/api/guide4app-pdf',
  canaryQTemplate: apiRoot + '/api/q-template',
  canaryQResult: apiRoot + '/api/q-result',
  canaryPubViewUser: apiRoot + '/api/public/view-user',
  canaryPubViewForm: apiRoot + '/api/public/view-form',
  canaryPubViewSummary: apiRoot + '/api/public/view-summary'
})

// For IE11 (disable ajax cache by timestamp parameter)
axios.interceptors.request.use(function (config) {
  if (typeof config.params === 'undefined') {
    config.params = {}
  }
  if (typeof config.params === 'object') {
    if (typeof URLSearchParams === 'function' && config.params instanceof URLSearchParams)
      config.params.append('_', Date.now())
    else
      config.params._ = Date.now()
  }

  return config
})

export default {
  namespaced: true,
  actions: {
    async request ({ rootState }, { method, apiName, path='', data, params }) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      if (rootState.auth.token) {
        headers['Authorization'] = 'Bearer ' + rootState.auth.token.jwtToken
      }

      const url = (endpoints[apiName] || '') + path
      const options = {
        method,
        url,  
        headers,
        data,
        params,
        httpAgent: new HttpAgent({ keepAlive: true }),
        httpsAgent: new HttpsAgent({ keepAlive: true }),
        timeout: 15000
      }
      return axios(options)
    },
    async get ({ dispatch }, requests) {
      requests.method = 'get'
      return dispatch('request', requests)
    },
    async post ({ dispatch }, requests) {
      requests.method = 'post'
      return dispatch('request', requests)
    },
    async put ({ dispatch }, requests) {
      requests.method = 'put'
      return dispatch('request', requests)
    },
    async delete ({ dispatch }, requests) {
      requests.method = 'delete'
      return dispatch('request', requests)
    },
    async download({ rootState }, { apiName, path='', data, params }) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Accept'] = 'application/pdf'
      if (rootState.auth.token) {
        headers['Authorization'] = 'Bearer ' + rootState.auth.token.jwtToken
      }
      const url = (endpoints[apiName] || '') + path
      const getFileNameFromHeader = (content, defaultName='download.pdf') => {
        const regex = content.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        if (regex === null) return defaultName
        return decodeURI(regex[1]) || defaultName
      }
      return axios
        .get(url, {
          headers,
          data,
          params,
          responseType: 'blob'
        })
        .then(({headers, data}) => {
          const contentDisposition = headers['content-disposition'] || ''
          const fileName = getFileNameFromHeader(contentDisposition)
  
          const link = document.createElement('a')
          const downloadUrl = window.URL.createObjectURL(new Blob([data]))
          link.href = downloadUrl
          link.setAttribute('download', fileName) //any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    }
  }
}