<template>
<div>

  <div id="app">
    <b-navbar id="navbar" type="dark" class="c-theme mb-0">
      <b-navbar-brand tag="h1" id="brand" class="pt-1 pb-0">
        <!-- Link is disabled when view public page -->
        <b-link to="/" class="text-reset text-decoration-none" :disabled="$route.meta.isPublic">
          <b-img id="logoImg" alt="canary" :src="require('./assets/canary.png')" class="ml-1 mr-2"></b-img>
          <b-img id="brandName" alt="brandName" :src="require('./assets/brandName.png')"></b-img>
        </b-link>
      </b-navbar-brand>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" v-if="showAuth && !!corporation">
        <b-nav-item-dropdown id="navbar-dd" :class="{'switched': isSwitched}" toggle-class="py-0" right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <b-avatar id="avatar" class="mr-2" variant="success"></b-avatar>
            <small id="corpName" class="d-none d-md-inline">{{ currentCorporation.corporation_name }} 様</small>
          </template>

          <b-dropdown-text class="text-nowrap d-inline d-md-none">
            <small>{{ currentCorporation.corporation_name }} 様</small>
          </b-dropdown-text>

          <b-dropdown-text class="text-nowrap">
            <small>利用期限：{{ currentCorporation.exp_date }}</small>
          </b-dropdown-text>

          <b-dropdown-divider v-if="corporation.childs.length"></b-dropdown-divider>
          <b-dropdown-group header="組織切り替え" v-if="corporation.childs.length">
            <b-dropdown-item v-if="isSwitched" @click="switchCorporation(corporation)">
              <small class="font-weight-bold text-primary">{{ corporation.corporation_name }} に戻る</small>
            </b-dropdown-item>
            <b-dropdown-item v-for="(child, index) in corporation.childs" :key="index"
                :class="{'d-none' : currentCorporation.corporation_id==child.corporation_id}"
                @click="switchCorporation(child)">
              <small><b-icon-person class="mr-2" aria-hidden="true"/>{{ child.corporation_name }}</small>
            </b-dropdown-item>
          </b-dropdown-group>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item to="/config">
            <small><b-icon-gear class="mr-2" aria-hidden="true"/>設定{{ isSwitched ? "（切り替え先の組織）" : "" }}</small>
          </b-dropdown-item>
          <b-dropdown-item @click="signOut">
            <small><b-icon-power class="mr-2" aria-hidden="true"/>サインアウト</small>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>

    <!-- Loading overlay -->
    <b-overlay id="loadingOverlay" :show="loading" variant="secondary" opacity=".25" z-index="2000" no-wrap>
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="success" font-scale="2"></b-spinner><br>
          <b-alert variant="success" v-if="loadingMessage" class="my-2 text-dark" show>{{ loadingMessage }}</b-alert>
        </div>
      </template>
    </b-overlay>

    <b-alert :show="!!error" variant="danger" class="mb-0">
      <b-icon-exclamation-square-fill class="lead mr-2"/>{{ error }}
    </b-alert>
    <b-alert :show="!!warning" variant="warning" class="mb-0" dismissible>
      <b-icon-exclamation-triangle class="lead mr-2"/>{{ warning }}
    </b-alert>
    
    <b-breadcrumb id="breadcrumb" class="d-print-none" v-show="routeDirs.length" :items="routeDirs"></b-breadcrumb>

    <b-icon class="back d-none d-md-inline d-print-none" v-if="routeDirs.length > 1" @click="moveToUpperDir"
      icon="arrow-left-circle-fill" scale="2.8" variant="secondary"/>

    <b-container id="container">
      <b-row align-h="center">
        <b-col lg="9">
          <router-view class="mt-3" @overlay="overlay($event)" />
        </b-col>
      </b-row>
    </b-container>
  </div>

  <footer id="footer" class="c-theme fixed-bottom d-print-none">
    <small style="float:right">
      &copy; 2020 <a href="https://www.jsp-com.co.jp/canaryalarm/" target="_blank" rel="noopener noreferrer">ジェイエスピー株式会社</a>
    </small>
  </footer>

</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import store from '@/store'

const { mapState: mapStateOfApp } = createNamespacedHelpers('app')

export default {
  name: 'App',
  data () {
    return {
      loading: false,
      loadingMessage: '',
      routeDirs: [],
      showAuth: false
    }
  },
  created () {
    this.changeRoute()
  },
  computed: {
    ...mapStateOfApp(['error', 'warning']),
    corporation() {
      return this.$store.getters['app/cachedCorporation']()
    },
    currentCorporation() {
      return this.$store.state.auth.corporation || {}
    },
    isSwitched() {
      if (this.$store.state.auth.corporation) {
        return this.$store.state.auth.rootCorporationId !== this.$store.state.auth.corporation.corporation_id
      }
      return false
    }
  },
  watch: {
    '$route': 'changeRoute'
  },
  methods: {
    async overlay ({action, message=''}) {
      this.loadingMessage = message
      this.loading = true
      await action()
      this.loading = false
      this.loadingMessage = ''
    },

    changeRoute () {
      this.refreshBreadCrumb(this.$route)
      this.showAuth = !this.$route.meta.isPublic && this.$store.state.auth.token
      // router.beforeEach ではなくこちらで実行
      store.dispatch('app/clearError', { root: true })
    },

    refreshBreadCrumb (to) {
      const list = []
      this.routeDirs = list

      if (!to.meta.isPublic && !!to.name) {
        list.push({ text: 'Home', to: { name: 'Home' }})
        if (to.name != 'Menu') {
          if (to.name == 'SendMail') {
            list.push({ text: 'メール送信', to: { name: 'Sendmail' }})
          } else if (to.name.startsWith('Response') || to.name == 'ResendMail') {
            list.push({ text: '回答確認', to: { name: 'Responses' }})
            if (to.name != 'Responses') {
              let dirs = to.path.split('/')
              list.push({ text: '集計結果', to: { name: 'ResponseSummary', id: dirs[1] }})
              if (to.name == 'ResponseDetail') {
                list.push({ text: '個人別' })
              } else if (to.name == 'ResendMail') {
                list.push({ text: 'メール再送信' })
              }
            }
          } else if (to.name.startsWith('Template')) {
            list.push({ text: '定型文一覧', to: { name: 'Templates' }})
            if (to.name != 'Templates') {
              list.push({ text: '定型文' })
            }
          } else if (to.name.startsWith('User')) {
            list.push({ text: 'メンバー一覧', to: { name: 'Users' }})
            if (to.name != 'Users') {
              if (to.name == 'UserId4App') {
                list.push({ text: 'アプリ連携情報' })
              } else {
                list.push({ text: 'メンバー' })
              }
            }
          } else if (to.name == 'AltUsers') {
            list.push({ text: 'メンバー一覧', to: { name: 'AltUsers' }})
          } else if (to.name == 'Config') {
            list.push({ text: '設定' })
          }
        }
        list[list.length - 1]['active'] = true
      }
    },

    switchCorporation(corporation) {
      this.loading = true
      // 切り替えを意図的に遅くする
      setTimeout(async () => {
        await store.dispatch('auth/switchCorporation', corporation, { root: true })
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
        const h = this.$createElement
        const messageVNode = h('div', [
          h('span', '組織を切り替えました'), 
          h('div', { class: ['my-2 ml-2', 'text-primary'] }, corporation.corporation_name)
        ])          
        this.msgBoxOk(messageVNode)
        this.loading = false
      }, 400)
    },

    moveToUpperDir() {
      const list = this.routeDirs
      if (list.length > 1)
        this.$router.push(list[list.length - 2].to)
    },

    async signOut() {
      await store.dispatch('app/signOut', { root: true })
    }
  }
}
</script>

<style>
/* IEをサポート対象とするため、変数値の利用（var）はしないこと  */
#app {
  font-family: "Helvetica Neue",
    Arial, 
    "Hiragino Kaku Gothic ProN", 
    "Hiragino Sans", 
    Meiryo, 
    sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #fff;
  line-height: 1.3;
  margin: 0;
  min-height: 100vh;
}
#breadcrumb {
  background: honeydew;
  line-height: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 900;
}
#breadcrumb li:first-child::before {
    content: '';
    display: inline-block;
    color: #6c757d;
    width: 1.2em;
    height: 0.8em;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='1 1 15 15' fill='%236c757d' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath d='M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
}
#container {
  padding-bottom: 60px;
}
@media print { 
  #container { 
    padding-top: 60px;
  } 
}
#sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1em 0 30px; 
  background: #fff;
  z-index: 899;
}
#footer {
  position: relative;
  padding: 5px 10px; 
  height: 30px;
  margin-top: -30px;
  z-index: 900;
}
#footer a {
  color: lightyellow;
}
#navbar {
  box-shadow:0 .5em 1em rgba(0,0,0,.05),inset 0 -1px 0 rgba(0,0,0,.1);
  padding: 0.1em 0.5em;
}
#navbar .dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
}
#navbar-dd {
  border-radius: .6em;
  padding: .2em 0;
}
#navbar-dd .dropdown-item:hover {
  background-color: lightblue;
}
.switched #avatar {
  background-color: #EDD634;
  border: solid 1px honeydew;
  color: honeydew;
}
#logoImg {
  height: 36px;
}
#brandName {
  vertical-align: middle;
  height: 28px;
  margin: 4px 5px;
}
#corpName {
  color: white;
}
#avatar {
  height: 2em;
  width: 2em;
}
.back {
  position: fixed;
  left: 30px;
  top: calc(50%);
  transition: 1s;
  opacity: 0.4;
}
.back:hover {
  cursor: pointer;
  opacity:1;
}
#loadingOverlay {
  position: fixed !important; /* for show with 100vh */
}

/* App classes */
.c-theme {
  color: whitesmoke;
  background-color: mediumseagreen;
}
.loading {
  margin: auto;
  text-align: center;
}
.table-loading {
  color: #6c757d;
}
.error {
  color: crimson;
  font-size: 90%;
}
.caution {
  color: crimson;
}
.userTag {
  background-color: mediumseagreen;
  opacity: 0.95;
  border-width: 1px;
  border-style: solid;
}
.questionCard .card-body {
  padding: .6rem 1.25rem .8rem;
  line-height: 1.5;
}
.attrBadge {
  font-weight: normal;
  padding: .3em .7em .3em;
  border-radius: .6em;
  vertical-align: text-bottom;
}
.smaller {
  font-size: 90%;
}
.inputForm {
  max-width: 680px;
  margin: auto;
}
.headerAttr {
  line-height: 1.5;
  white-space: nowrap;
}
.help {
  position: fixed;
  right: 30px;
  bottom: 50px;
  transition: 1s;
  opacity: 0.7;
  z-index: 901;
}
.help:hover {
  opacity:1;
}
.helpCaption {
  display: none;
}
.helpIcon:hover+.helpCaption, .helpCaption:hover {
  float: right;
  display: block;
}
@media screen and (max-width: 768px) {
  .help {
    display: none;
  }
}
.choiceGroup+.choiceGroup input,
.choiceGroup+.choiceGroup label,
.choiceGroup+.choiceGroup button {
  border-top: none;
}
.buttonGroup {
  margin-top: 2em;
  margin-bottom: 2em;
}
.buttonGroup .btn {
  min-width: 100px;
  margin-bottom: 0.5em; /* 折り返し表示対応 */
}
.min-w-25 {
  min-width: 25%;
}
.min-w-50 {
  min-width: 50%;
}
.line-higher {
  line-height: 1.5;
}

/* vue-tour modal */
.v-step {
  font-size: 80%;
  white-space: nowrap;
  color: #333 !important;
  background-color: #ff5 !important;
  border-color: #ffeeba !important;
}
.v-step__content {
  text-align: left;
  line-height: 1.6;
}
.v-step__button {
  color: #212529 !important;
  border-color: #212529 !important;
}
.v-step__button:hover {
  background-color: #ffc107 !important;
}
.v-step__button-skip {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.v-step__button-stop {
  color: #007bff !important;
  border-color: #007bff !important;
}

/* bootstrapvue form-radio */
.btn-group-toggle .btn:hover {
  color: #fff !important;
  background-color: mediumaquamarine !important;
  border-color: mediumaquamarine !important;
}
.btn-group-toggle .btn.active {
  color: #fff !important;
  background-color: #3cb371 !important;
  border-color: #3cb371 !important;
}
.btn-group-toggle .btn:not(.active) {
  color: gray;
  border-color: rgba(0,0,0,.25);
}
.btn-group-toggle .btn+.btn {
  border-left: none;
}

/* bootstrapvue table */
.table-active > td {
  background-color: lightblue !important;  
}
.table td, .table th {
  padding: .4em;
  vertical-align: middle;
}
.page-item.active .page-link {
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
}

/* bootstrapvue form-control, etc. */
.form-control {
  border: 1px solid rgba(0,0,0,.25);
}
.card {
  border: 1px solid rgba(0,0,0,.35);
}
.card .dropdown-item {
  padding: .5em .5em .5em 1.5em;
  font-size: .95em;
}
.card .input-group-prepend {
  width: 3em;  
}
.btn {
  padding: 0.35em 0.6em;
}
.btn .spinner-border {
  margin-right: 0.3em;
}
.dropdown-header {
  padding: .3em 1.5em .5em;
}
.dropdown-item {
  padding: .4em 1.5em;
}

/* bootstrapvue modal */
.modalHeader {
  padding: 0.5em 1em;
}
.modal-title {
  font-size: 1.1em;
}
.modal-footer .btn {
  min-width: 60px;
}

/* customize amplify-authenticator */
.signin > div > div {
  background-color: palegreen;
  border-style: solid;
  border-width: 1px;
  padding: 25px 30px;
  min-width: inherit;
}
.signin a {
  text-decoration: underline;
}
.signin input {
  padding: 10px;
}
.signin button {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
  margin-bottom: .5em;
}

.spotlight .form-control,
.spotlight .card {
  animation: spotlighter 1s ease-in;
}

@keyframes spotlighter {
  0% {
    box-shadow: 0 0 0 0.2em rgba(128, 128, 128, 0.25);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

@keyframes sending {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  70%, 100% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@media screen and (max-width:768px) {
  body, .input-group-text {
    font-size: 0.9em;
  }
  .form-control, .btn, .custom-select {
    font-size: 0.9em;
  }
}
@media screen and (min-width:768px) {
  .font-smaller {
    font-size: 0.85rem;
  }
}
</style>
