import Vue from 'vue'
import Vuelidate from 'vuelidate'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Auth } from '@aws-amplify/auth'
import { I18n, Logger } from '@aws-amplify/core'
import { AmplifyPlugin } from 'aws-amplify-vue'
import VueTour from 'vue-tour'
import { mapGetters } from 'vuex'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import awsconfig from '@/aws-exports'
import { messages } from '@/i18n/amplify/messages'

// Install
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(AmplifyPlugin, { Auth, I18n, Logger, 'AuthClass': Auth.constructor })
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// Mixin
Vue.mixin({
  computed: {
    ...mapGetters({
      corporationId: 'auth/corporationId',
      memberCorporationId: 'auth/memberCorporationId'
    }),
  },
  methods: {
    msgBoxOk (message, options) {
      const defaults = {title: 'メッセージ', size: 'sm', centered: true, 
        autoFocusButton: 'ok', headerClass: 'modalHeader c-theme'}
      return this.$bvModal.msgBoxOk(message, Object.assign(defaults, options || {}))
    },
    msgBoxConfirm (message, options) {
      const defaults = {'title': '確認', size: 'sm', centered: true, 
        okTitle: 'OK', okVariant: 'danger', 
        cancelTitle: 'キャンセル', cancelVariant: 'outline-secondary',
        autoFocusButton: 'cancel', headerClass: 'modalHeader c-theme'}
      return this.$bvModal.msgBoxConfirm(message, Object.assign(defaults, options || {}))
    }
  }
})

Vue.config.devtools = true
Vue.config.productionTip = false

/*
Vue.config.errorHandler = (err, vm, info) => {
  store.dispatch('app/error', err);
}
window.addEventListener("error", event => {
  store.dispatch('app/error', event.error);
})
window.addEventListener("unhandledrejection", event => {
  store.dispatch('app/error', event.reason);
})
*/

Auth.configure(awsconfig)
I18n.putVocabularies(messages)
I18n.setLanguage('ja')

let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.vm = vm // for debug