import Vue from 'vue'
import VueRouter from 'vue-router'
import { AmplifyEventBus } from 'aws-amplify-vue'

import Login from '@/views/Login.vue'
import Menu from '@/views/Menu.vue'

// route level code-splitting
// which is lazy-loaded when the route is visited.
const SendMail = () => import(/* webpackChunkName: "main" */ '../views/SendMail.vue')
const ResendMail = () => import(/* webpackChunkName: "main" */ '../views/ResendMail.vue')
const Responses = () => import(/* webpackChunkName: "main" */ '../views/Responses.vue')
const ResponseSummary = () => import(/* webpackChunkName: "main" */ '../views/ResponseSummary.vue')
const ResponseDetail = () => import(/* webpackChunkName: "main" */ '../views/ResponseDetail.vue')
const Users = () => import(/* webpackChunkName: "main" */ '../views/Users.vue')
const UserDetail = () => import(/* webpackChunkName: "main" */ '../views/UserDetail.vue')
const AltUsers = () => import(/* webpackChunkName: "main" */ '../views/AltUsers.vue')
const Templates = () => import(/* webpackChunkName: "main" */ '../views/Templates.vue')
const TemplateDetail = () => import(/* webpackChunkName: "main" */ '../views/TemplateDetail.vue')
const Config = () => import(/* webpackChunkName: "main" */ '../views/Config.vue')
const About = () => import(/* webpackChunkName: "main" */ '../views/About.vue')

const ViewForm = () => import(/* webpackChunkName: "public" */ '../views/ViewForm.vue')
const ViewUser = () => import(/* webpackChunkName: "public" */ '../views/ViewUser.vue')
const ViewResponseSummary = () => import(/* webpackChunkName: "public" */ '../views/ViewResponseSummary.vue')
const ViewResponseDetail = () => import(/* webpackChunkName: "public" */ '../views/ViewResponseDetail.vue')

const Guide4App = () => import(/* webpackChunkName: "public" */ '../views/Guide4App.vue')

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signIn',
    name: 'Login',
    meta: { isPublic: true },
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Menu
  },
  {
    path: '/sendmail',
    name: 'SendMail',
    component: SendMail
  },
  {
    path: '/responses',
    name: 'Responses',
    component: Responses
  },
  {
    path: '/responses/:id',
    name: 'ResponseSummary',
    props: (route) => ({ questionnaireId: route.params.id }),
    component: ResponseSummary
  },
  {
    path: '/resendmail/:id',
    name: 'ResendMail',
    props: (route) => ({ questionnaireId: route.params.id }),
    component: ResendMail
  },
  {
    path: '/responses/:id/:ruid',
    name: 'ResponseDetail',
    props: (route) => ({ questionnaireId: route.params.id, ruid: route.params.ruid }),
    component: ResponseDetail
  },
  {
    path: '/templates/new',
    name: 'TemplateNew',
    props: () => ({ isNew: true }),
    component: TemplateDetail
  },
  {
    path: '/templates/:id',
    name: 'TemplateUpdate',
    props: (route) => ({ isNew: false, p_caption: route.params.id }),
    component: TemplateDetail
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates
  },
  {
    path: '/users/new',
    name: 'UserNew',
    props: () => ({ isNew: true }),
    component: UserDetail
  },
  {
    path: '/users/temp/:id',
    name: 'UserNewTemp',
    props: (route) => ({ isNew: true, p_temp_user_id: route.params.id }),
    component: UserDetail
  },
  {
    path: '/users/:id',
    name: 'UserUpdate',
    props: (route) => ({ isNew: false, p_user_id: route.params.id }),
    component: UserDetail
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/altusers',
    name: 'AltUsers',
    component: AltUsers
  },
  {
    path: '/config',
    name: 'Config',
    component: Config
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/viewform/:corpId/:ruid',
    name: 'ViewForm',
    props: (route) => ({ corpId: route.params.corpId, ruid: route.params.ruid }),
    meta: { isPublic: true },
    component: ViewForm
  },
  {
    path: '/viewuser/:corpId/:corpToken',
    name: 'ViewUser',
    props: (route) => ({ corpId: route.params.corpId, corpToken: route.params.corpToken }),
    meta: { isPublic: true },
    component: ViewUser
  },
  {
    path: '/viewsummary/:corpId/:qId/:secretToken',
    name: 'ViewResponseSummary',
    props: (route) => ({ corpId: route.params.corpId, questionnaireId: route.params.qId, secretToken: route.params.secretToken }),
    meta: { isPublic: true },
    component: ViewResponseSummary
  },
  {
    path: '/viewdetail/:corpId/:ruid/:secretToken',
    name: 'ViewResponseDetail',
    props: (route) => ({ corpId: route.params.corpId, ruid: route.params.ruid, secretToken: route.params.secretToken }),
    meta: { isPublic: true },
    component: ViewResponseDetail
  },
  {
    path: '/app/guide',
    name: 'Guide4App',
    meta: { isPublic: true },
    component: Guide4App
  },
  {
    // 定義されていないパスへの対応
    path: '*',
    redirect: '/signIn'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const hasNonPublic = to.matched.some(record => !record.meta.isPublic)
  const moveToSignIn = to.name == 'Login' && Object.keys(to.query).length == 0
  
  let authUser = null
  if (hasNonPublic || moveToSignIn) {
    authUser = await store.dispatch('app/authUser', { root: true })
  }

  if (authUser) {
    //console.log("サインイン：有効")
    if (moveToSignIn) {
      //console.log("- メニューへ遷移")
      next({ name: 'Home' })
    } else {
      //console.log("- 警告クリア＋移動,", to.name)
      store.dispatch('app/clearWarning', { root: true })
      next()
    }
  } else {
    //console.log("サインイン：無効")
    if (hasNonPublic || moveToSignIn) {
      //console.log("- トークン破棄＋サインインへの遷移")
      store.dispatch('auth/destroy', null, { root: true })
      if (moveToSignIn)
        next() // 引数なしで移動しないと再度beforeEachが実行される
      else
        next({ name: 'Login' })  
    } else {
      // 移動
      //console.log("- 移動,", to.name)
      next()
    }
  }
})

// For amplify-authenticator
// eventbus: register only once
AmplifyEventBus.$on('authState', (state) => {
  const pushPathes = {
    signedOut: () => {
      if (router.currentRoute.name != 'Login') {
        router.push({ name: 'Login' })
      }
    },
    signedIn: async () => {
      router.push({ name: 'Home' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

export default router
