export const messages = {
  ja: {
    'Back to Sign In': 'サインイン画面に戻る',
    'Code': '検証コード',
    'Confirm': '確認',
    'Confirm Sign Up': 'サインアップの確認',
    'Confirmation Code': '確認コード',
    'Confirmation code cannot be empty': '確認コードは必須入力です',
    'Create Account': '新規登録',
    'Create a new account': 'アカウントの新規登録',
    'Create account': '新規登録',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須入力です',
    'Email': 'メールアドレス',
    'Enter new password': '新しいパスワードを入力してください',
    'Enter your code': '確認コードを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Enter your Username': 'ユーザー名を入力してください',
    'Forget your password? ': 'パスワードをお忘れの方 ',
    'Have an account? ': 'アカウント登録済みの方 ',
    'Hello': 'こんにちは ',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    'Invalid verification code provided, please try again.': '指定された確認コードが無効です',
    'Lost your code? ': 'コードを紛失した方 ',
    'New Password': '新しいパスワード',
    'No account? ': 'アカウント未登録の方 ',
    'Password': 'パスワード',
    'Phone Number': '電話番号',
    'Resend Code': '確認コードの再送',
    'Reset password': 'パスワードのリセット',
    'Reset your password': 'パスワードのリセット',
    'Send Code': 'コードの送信',
    'Sign In': 'サインイン',
    'Sign Out': 'サインアウト',
    'Sign in': 'サインイン',
    'Sign in to your account': 'サインイン',
    'Submit': '送信',
    'User does not exist': 'ユーザーが存在しません',
    'Username': 'ユーザー名',
    'Username cannot be empty': 'ユーザー名は必須入力です',
    'Username/client id combination not found.': 'ユーザー名が見つかりません'
  }
}