const awsmobile = {
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_jcwxFWs3M',
    userPoolWebClientId: '10jglehqvjjt5g7eofv88f9jd9', 
    // OPTIONAL - customized storage object
    //storage: window.sessionStorage,
  }
}

export default awsmobile
