import { Auth } from '@aws-amplify/auth'
import { AmplifyEventBus } from 'aws-amplify-vue'

export default {
  namespaced: true,
  state: {
    corporation: null,
    cachedDate: null,
    error: null,
    warning: null
  },
  getters: {
    today: () => () => {
      // 毎回算出させるため、メソッドとして実装する
      const d = new Date()
      return d.getFullYear() + '-' + ('00'+(d.getMonth()+1)).slice(-2) + '-'+ ('00'+d.getDate()).slice(-2)
    },
    generateUuid: () => () => {
      // 毎回算出させるため、メソッドとして実装する
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/x/g, () => Math.floor(Math.random() * 16).toString(16))
        .replace(/y/g, () => (Math.floor(Math.random() * 4) + 8).toString(16))
    },
    generateShortId: () => () => {
      // 毎回算出させるため、メソッドとして実装する
      const rnd3char = () => ('000' + (36 ** 3 * Math.random() | 0).toString(36)).slice(-3)
      return rnd3char() + rnd3char()
    },
    cachedCorporation: (state, getters, rootState) => () => {
      // 毎回チェックさせるため、メソッドとして実装する
      if (state.cachedDate == getters.today()) {
        if (state.corporation.corporation_id == rootState.auth.rootCorporationId) {
          // キャッシュ日付が当日 かつ 同一企業ID の場合にキャッシュを返却する
          return state.corporation
        }
      }
      return null
    }
  },
  mutations: {
    cacheCorporation (state, { corporation, today }) {
      state.corporation = corporation
      state.cachedDate = today
    },
    clearCache (state) {
      state.corporation = null
      state.cachedDate = null
    },
    error (state, message) {
      state.error = message
    },
    warning (state, message) {
      state.warning = message
    }
  },
  actions: {
    async authUser({ commit, dispatch, getters }) {
      // キャッシュを使用しないようbypassCache指定する（corporationId書き換えを防ぐ）
      const user = await Auth.currentAuthenticatedUser({bypassCache: true})
        .then((data) => data && data.signInUserSession ? data : null)
        .catch(() => null)
    
      if (user) {
        // サインインの会社情報
        dispatch('auth/create', user, { root: true })
            
        let corp = getters.cachedCorporation()
        if (!corp) {
          // キャッシュが無い場合
          const corporationId = user.attributes['custom:corporationId']
          const params = {'corporation_id': corporationId}
          corp = await dispatch('http/get', { apiName: 'canaryCorp', params: params }, { root: true })
            .then(res => res.data)
            .catch(() => null)
          if (!corp) {
            dispatch('signOut', '企業情報の取得に失敗しました。')
            return null
          }
          // 対象の会社情報
          dispatch('auth/switchCorporation', corp, { root: true })

          // キャッシュ時日付も更新するため、取得した場合にのみ設定
          commit('cacheCorporation', { corporation: corp, today: getters.today() })
          commit('warning', null)
          commit('error', null)
        }
      
        const dsec = 24 * 60 * 60 * 1000
        const diffDays = Math.ceil((Date.parse(corp.exp_date) - Date.now()) / dsec)
        if (diffDays < 0) {
          dispatch('signOut', '利用期限を過ぎています。販売会社にお問い合わせください。')
          return null
        } else if (diffDays <= 40) {
          commit('warning', '利用期限が近づいています。（残り' + diffDays + '日）')
        }
        return user
      }
      return null
    },
    async signOut({ commit, dispatch }, error=null) {
      await Auth.signOut()
      commit('clearCache')
      commit('warning', null)
      commit('error', error)
      dispatch('auth/destroy', null, { root: true })
      AmplifyEventBus.$emit('authState', 'signedOut')
    },
    clearError ({ commit }) {
      commit('error', null)
    },
    clearWarning ({ commit }) {
      commit('warning', null)
    }
  }
}
