<template>
  <b-container>
    
    <b-row align-h="center">
      <b-img id="signinImg" alt="canary" :src="require('../assets/logo.png')"></b-img>
    </b-row>

    <div v-show="!showPresetParams && showSignIn">
      <b-row class="signin" align-h="center">
        <amplify-authenticator :authConfig="authConfig"></amplify-authenticator>
      </b-row>
    </div>

    <div v-if="showPresetParams && showSignIn">
      <b-row class="my-3" align-h="center">
        下記のサインイン情報を使用します。よろしいですか？<br>
      </b-row>
      <b-row v-if="this.presetUserName">
        <b-col sm="2" offset-sm="3">
          <label>ユーザー名：</label>
        </b-col>
        <b-col sm="4">
          <code>{{ this.presetUserName }}</code>
        </b-col>
      </b-row>
      <b-row v-if="this.presetPassword">
        <b-col sm="2" offset-sm="3">
          <label>パスワード：</label>
        </b-col>
        <b-col sm="4">
          <code>{{ this.presetPassword }}</code>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-center" v-if="!failure">
          <b-button id="backButton" variant="primary" @click="presetParams">使用する</b-button>
          <span class="mx-2"/>
          <b-button id="sendButton" variant="secondary" @click="unpresetParams">使用しない</b-button>
        </b-col>
        <b-col class="text-center" v-else>
          <span class="caution">情報の設定に失敗しました。画面更新後に再度実行してください。</span><br>
          <br>
          <b-button id="reloadButton" variant="primary" @click="reloadPage">更新</b-button>
        </b-col>
      </b-row>
    </div>

  </b-container>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false
        }
      },
      showPresetParams: false,
      presetUserName: '',
      presetPassword: '',
      failure: false
    }
  },
  async mounted () {
    if (this.$route.query.userName || this.$route.query.password) {
      await this.$store.dispatch('app/signOut', null, { root: true })
      this.showPresetParams = true
      this.presetUserName = this.$route.query.userName || ''
      this.presetPassword = this.$route.query.password || ''
    }
  },
  computed: {
    showSignIn() {
      return !this.$store.state.auth.token || this.presetUserName || this.presetPassword
    }
  },
  methods: {
    presetParams () {
      this.$nextTick(() => {
        const createEvent = (type) => {
          if (typeof Event === 'function') {
            return new Event(type)
          }
          // for IE11
          const event = document.createEvent('Event')
          event.initEvent(type, true, true)
          return event
        }
        // ビュー全体がレンダリングされた後にのみ実行されるコード
        const userEl = this.$el.querySelector('input[data-test="username-input"]')
        const passwordEl = this.$el.querySelector('input[data-test="sign-in-password-input"]')
        if (!userEl || !passwordEl) {
          this.failure = true
          return
        }
        userEl.value = this.presetUserName
        userEl.dispatchEvent(createEvent('input'))
        userEl.dispatchEvent(createEvent('keyup'))
        passwordEl.value = this.presetPassword
        passwordEl.dispatchEvent(createEvent('input'))
        passwordEl.dispatchEvent(createEvent('keyup'))
        this.showPresetParams = false
      })
    },
    unpresetParams () {
      this.showPresetParams = false
    },
    reloadPage () {
      window.location.reload()
    }
  }
}
</script>

<style scoped>
#signinImg {
  margin: 20px;
}
</style>